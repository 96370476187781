.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* logo */
.layout Header .demo-logo img {
  margin-right: 308px;
  height: 100%;
}

/* 菜单 */
.main-menu {
  margin-right: 64px;
  line-height: 120px;
  white-space: nowrap;
}

.main-menu-item {
  font-size: 22px;
  letter-spacing: 5px;
  cursor: pointer;
}

/* 子菜单 */
.menu-submenu {
  display: flex;
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  height: 88px;
  background-color: rgba(56, 51, 52, 0.4);
  padding-left: 730px;
  padding-top: 20px;
}

.menu-submenu-item {
  margin-right: 80px;
  width: 114px;
  height: 43px;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
}

.layout Header nav .menu-submenu a {
  display: inline-block;
  width: 114px;
  line-height: 50px;
  font-size: 22px;
  color: #757575;
  letter-spacing: 5px;

}

.layout Header nav .menu-submenu .active {
  border-bottom: 1px solid #fff !important;
}

.layout img {
  user-select: none;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1520px) {
  .layout Header .demo-logo img {
    margin-right: 160px;
  }

  .menu-submenu {
    padding-left: 582px;
  }
}