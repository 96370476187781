.solution-box {
    width: 1552px;
    margin: 0 auto;
    padding-bottom: 64px;
}

.solution-box img {
    width: 100%;
    height: 100%;
}

/* 卡片 */
.solution-card {
    width: 100%;
    background-color: #fff;
    margin-bottom: 64px;
    padding: 60px 125px 0;
    border-radius: 60px;
}

/* 标题 */
.solution-card .title {
    /* width: 500px; */
    height: 72px;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    background-image: linear-gradient(to right, #7474BF, #348AC7);
    -webkit-background-clip: text;
    color: transparent;

    border-bottom: 3px solid;
    /* 下边框渐变 */
    border-image: linear-gradient(to right, #7474BF, #348AC7) 4;
}

/* 内容 */
.solution-card .text {
    line-height: 50px;
    font-weight: 400;
    font-size: 25px;
    color: #8c8c8c;
}

/* 卡片 */
.solution-cardNew {
    display: flex;
    padding: 39px 111px 39px 67px;
    align-items: center;
}

@media screen and (max-width: 1520px) {
    .solution-box {
        width: 1280px;
    }
}