.ProductCenter {
    background-color: #f9f9f9;
}



/* 产品智慧人行&智慧梯行 */
.productCenter-people {
    position: relative;
    width: 1552px;
    margin: 0 auto;
    transform: scale(0.8);
    transform-origin: top center;
}


.productCenter-ladder {
    position: relative;
    width: 1552px;
    margin: 0 auto;
    /* padding: 64px 0 237px 0; */
    transform: scale(0.8) translateY(-25%);
    transform-origin: top center;
}

.productCenter-imgDiv {
    cursor: pointer;
}

/* //图片下的黑色设备名字 */
.productCenter-content-item-title {
    font-size: 36px;
    font-weight: 400;
    margin: 8px 0 14px 0;
    cursor: pointer;
}

.productCenter-title {
    position: relative;
    height: 60px;
}

.productCenter-content {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #000;
}



.productCenter-content-item {
    display: flex;
    flex: 0 0 calc(33.33% - 32px);
    /* 在原有占比基础上减去 32px 的 margin */
    margin-right: 32px;
    margin-top: 32px;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}




.productCenter-content-item:nth-child(3n) {
    margin-right: 0;
}

@media screen and (max-width: 1520px) {

    .productCenter-people,
    .productCenter-ladder {
        width: 1280px;

    }

    .productCenter-content-item {
        margin: 32px auto 0;
    }

    .productCenter-content-item:nth-child(3n) {
        margin-right: 32px;
    }
}