/* 面包屑 */
.about-crumb {
    width: 100%;
    height: 88px;
    padding-left: 73px;
    font-size: 24px;
    background: #F5F5F5;
    display: flex;
    align-items: center;
}

/* 文字盒子 */
.about-box {
    width: 100%;
    background: #F9F9F9;
}

.about-box img {
    width: 100%;
}

.about-article {
    width: 1552px;
    margin: 0 auto;
}

.top-img-margin {
    margin: 55px 0 75px 0;
}

.about-title {
    height: 92px;
    border-bottom: 1px solid #020052;
}

.about-title p {
    height: 72px;
    font-size: 48px;
    font-weight: 500;
    color: #020052;
    line-height: 56px;
    letter-spacing: 6px;
}

.about-text {
    padding: 60px 0;

}

.about-text p {
    font-size: 26px;
    color: #505050;
    line-height: 30px;
}

@media screen and (max-width: 1520px) {
    .about-article {
        width: 1280px;
    }
}