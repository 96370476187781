.container-houseTop,
.container-houseBottom {
    width: 100% !important;
}


.container-houseTop .swiper-slide img {
    display: block;
    width: 100%;
    height: 750px;
    object-fit: cover;
}

.container-houseBottom .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-houseTop .swiper-pagination-bullet {
    background: #ededed;
}

.container-houseTop .swiper-pagination-bullet-active {
    background: #fff;
}

/* 产品展示区域 */
.products-box {
    width: 1552px;
    margin: 0 auto;
}

.house-scene_text {
    margin-top: 84px;
    text-align: center;
}

.house-scene_text p {
    display: inline-block;
    width: 100%;
    color: #757575;
    margin-top: 0;
}

/* icon */
.iconBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-bottom: 112px;
}

.iconItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 162px;
    color: #5F5F5F;
}

.iconItem img {
    margin-bottom: 16px;
}

.iconItem:last-child {
    margin-right: 0;
}

/* 产品使用位置展示 */
.products_img {
    width: 100%;
    height: 832px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

}

.products_img_item {
    height: 100%;
}

.products_img_item1 {
    margin-bottom: 32px;
}

.products_img_item1,
.products_img_item2 {
    display: flex;
    align-items: flex-start;
}

.products_img_item1 img:first-child,
.products_img_item2 img:first-child {
    margin-right: 32px;
}

/* 开关 */
.switchBox {
    margin-top: 32px;
    width: 100%;
    height: 706px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.switchBox .switchBox_item_left {
    height: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.switchBox_item_right {
    display: flex;
    flex-wrap: nowrap;
}

.switchBox_item_right * {
    margin-right: 28px;
}

.switchBox_item_right img:last-child {
    margin-right: 0px;
}

.switchBox_item_right1 {
    margin-bottom: 96px;
}

/* 智能场景 */
.intelligent {
    width: 100%;
    height: 192px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intelligent .intelligent_text {
    text-align: center;
}

.intelligent .intelligent_text p {
    display: inline-block;
    width: 100%;
    color: #fff;
    margin-top: 0;
}


.container-houseBottom .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; */
}

.container-houseBottom .swiper-pagination-bullets {
    width: 100% !important;
    height: 48px !important;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    bottom: 45px;
}

.container-houseBottom span.swiper-pagination-bullet {
    width: 143px;
    height: 100%;
    font-size: 32px;
    border-radius: 0px;
    background: none;
    text-align: center;
    line-height: 38px;
    opacity: 1;
    color: white;
}

.container-houseBottom span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    border-bottom: 3px solid;
    /* 下边框渐变 */
    border-image: linear-gradient(to right, #13d4f7, #abfcfa) 4;
}

@media screen and (max-width: 1520px) {

    .products-box {
        width: 1280px;

    }

    .scene-text-p {
        font-size: 22px !important;
    }

}