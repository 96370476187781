.home {
    background-color: #000;
    color: #fff;
}



/* 顶部轮播图 */
.container-homeTop {
    width: 100% !important;
}


.container-homeTop .swiper-slide img {
    display: inline-block;
    width: 100%;
    height: 750px;
    object-fit: cover;
}

.container-homeTop .swiper-slide a {
    display: flex;
    align-items: center;
    justify-content: center;
}


.swiper-pagination {
    margin-bottom: 10px;
}

.container-homeTop .swiper-pagination-bullet {
    background: #ededed;
}

.container-homeTop .swiper-pagination-bullet-active {
    background: #fff;
}

/* 中部轮播图 */
.container-homeCenter {
    position: relative;
    width: 100%;
    height: 870px;
}

.container-homeCenter .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 500ms;
    transform: scale(0.7);
    flex-shrink: 0;
}

.container-homeCenter .swiper-slide a {
    /* background: #fff; */
    padding: 10px;
    margin: 0 auto;
    display: block;
    border-radius: 14px;
}

.container-homeCenter .swiper-slide img {
    max-width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
}

.container-homeCenter .swiper-slide-active,
.container-homeCenter .swiper-slide-duplicate-active {
    /* width: 55% !important; */
    margin: 0 auto;
    transform: scale(1.2);
}

.container-homeCenter .swiper-button-next {
    height: 96px;
    width: 96px;
    line-height: 96px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
}

.container-homeCenter .swiper-button-prev {
    height: 96px;
    width: 96px;
    line-height: 96px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
}

/* 居中文字 */

.scene_text {
    margin-top: 84px;
    text-align: center;

}

p {
    display: inline-block;
    width: 100%;
    margin-top: 0;
}

.btn {
    background-color: #000;
    margin-top: 6px;
    margin-bottom: 34px;
    color: #fff;
    width: 160px;
    height: 40px;
}

.home-marginBox {
    width: 1552px;
    margin: 0 auto;
}

/* 产品中心 */
.Product_Center_Topimg {
    width: 100%;
    margin-bottom: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProductLeft {
    width: 1024px;
    height: 560px;
    background-image: url("../../static/home/homeBg1.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 32px;
}

.ProductLeft:last-child {
    margin-right: 0;
}

/* //盒子里的图片跟文字布局 */
.ProductLeft img {
    margin: 95px 267px 0 246px;
}

.ProductLeft .text {
    margin-top: 51px;
    margin-left: 54px;
}

/* 三张图的布局 */
.ProductRight {
    width: 496px;
    height: 560px;
    margin-right: 32px;
    background: #161616;
}

.ProductRight:last-child {
    margin-right: 0;
}

.ProductRight-img {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ProductRight .text {
    margin-left: 47px;
}

.ProductRight .textP {
    margin: 14px 0 9px 0;
}

/* 商务案例 */
.Business_Case {
    width: 100%;
    height: 1287px;
}


.Business1 {
    width: 100%;
    height: 560px;
    margin-bottom: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BusinesItem {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BusinesItem1 {
    width: 757px;
    height: 560px;
    margin-right: 35px;
    background-image: url("../../static/home/five.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BusinesItemText {
    font-size: 48px;
    text-align: center;
    line-height: 48px;
}

.BusinesItemText .btn {
    background-color: rgba(0, 0, 0, 0)
}

.BusinesItem2 {
    width: 760px;
    height: 560px;
    background-image: url("../../static/home/cloud.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}